import React from "react"
import ElementContactForm from "./ElementContactForm"
import ElementContent from "./ElementContent"
import ElementContent2Columns from "./ElementContent2Columns"
import ElementContent3Columns from "./ElementContent3Columns"
import ElementContent4Columns from "./ElementContent4Columns"
import Element4columnsBlogContent from "./Element4columnsBlogContent"
import ElementFullWidthImage from "./ElementFullWidthImage"
import ElementCTABlock from "./ElementCTABlock"
import ElementCalloutBlock from "./ElementCalloutBlock"
import ElementIntroBlockWithAuthor from "./ElementIntroBlockWithAuthor"
import ElementImageWithTextOverlay from "./ElementImageWithTextOverlay"
import ElementEmbedBlock from "./ElementEmbedBlock"
import ElementInstagramFeed from "./ElementInstagramFeed"
import ElementEnquireForm from "./ElementEnquireForm"
import ElementOneColumnText from "./ElementOneColumnText"
import ElementTwoColumnsText from "./ElementTwoColumnsText"
import ElementThreeColumnsText from "./ElementThreeColumnsText"
import ElementFloorplansBlock from "./ElementFloorplansBlock"
import ElementFeaturedProduct from "./ElementFeaturedProduct"
import ElementRelatedBlogArticles from "./ElementRelatedBlogArticles"
import ElementGallery from "./ElementGallery"
import ElementImageAndText from "./ElementImageAndText"
import ElementNewsletter from "./ElementNewsletter"
import ElementLocationMap from "./ElementLocationMap"
import ElementRecentViewed from "./ElementRecentViewed"
import ElementUserForm from "./ElementUserForm"
import ElementFeaturedProducts from "./ElementFeaturedProducts"

const Elements = ({data}) => {
    const elements_data = JSON.parse(data)
    return (
            <div>
                {elements_data.map((item) => 
                    renderElement(item)
                )}
            </div>
        )
}

function renderElement(element){
    var elementTemplate
    var className = element.ClassName
    if(className === 'DNADesign\\ElementalVirtual\\Model\\ElementVirtual'){
        var element_data = JSON.parse(element.Data)
        className = element_data.ClassName
    }    
    switch(className){
        case 'DNADesign\\Elemental\\Models\\ElementContent':
            elementTemplate = <ElementContent data={element} key={`ElementContent${element.ID}`}/>
            break
        case 'ElementContactForm':
            elementTemplate = <ElementContactForm data={element} key={`ElementContactForm${element.ID}`}/>
            break
        case 'ElementContent2Columns':
            elementTemplate = <ElementContent2Columns data={element} key={`ElementContent2Columns${element.ID}`}/>
            break
        case 'ElementContent3Columns':
            elementTemplate = <ElementContent3Columns data={element} key={`ElementContent3Columns${element.ID}`}/>
            break
        case 'ElementContent4Columns':
            elementTemplate = <ElementContent4Columns data={element} key={`ElementContent4Columns${element.ID}`}/>
            break
        case 'Element4columnsBlogContent':
            elementTemplate = <Element4columnsBlogContent data={element} key={`Element4columnsBlogContent${element.ID}`}/>
            break
        case 'ElementFullWidthImage':
            elementTemplate = <ElementFullWidthImage data={element} key={`ElementFullWidthImage${element.ID}`}/>
            break
        case 'ElementCTABlock':
            elementTemplate = <ElementCTABlock data={element} key={`ElementCTABlock${element.ID}`}/>
            break
        case 'ElementCalloutBlock':
            elementTemplate = <ElementCalloutBlock data={element} key={`ElementCalloutBlock${element.ID}`}/>
            break
        case 'ElementIntroBlockWithAuthor':
            elementTemplate = <ElementIntroBlockWithAuthor data={element} key={`ElementIntroBlockWithAuthor${element.ID}`}/>
            break
        case 'ElementImageWithTextOverlay':
            elementTemplate = <ElementImageWithTextOverlay data={element} key={`ElementImageWithTextOverlay${element.ID}`}/>
            break
        case 'ElementEmbedBlock':
            elementTemplate = <ElementEmbedBlock data={element} key={`ElementEmbedBlock${element.ID}`}/>
            break
        case 'ElementInstagramFeed':
            elementTemplate = <ElementInstagramFeed data={element} key={`ElementInstagramFeed${element.ID}`}/>
            break
        case 'ElementEnquireForm':
            elementTemplate = <ElementEnquireForm data={element} key={`ElementEnquireForm${element.ID}`}/>
            break
        case 'ElementOneColumnText':
            elementTemplate = <ElementOneColumnText data={element} key={`ElementOneColumnText${element.ID}`}/>
            break
        case 'ElementTwoColumnsText':
            elementTemplate = <ElementTwoColumnsText data={element} key={`ElementTwoColumnsText${element.ID}`}/>
            break
        case 'ElementThreeColumnsText':
            elementTemplate = <ElementThreeColumnsText data={element} key={`ElementThreeColumnsText${element.ID}`}/>
            break
        case 'ElementFloorplansBlock':
            elementTemplate = <ElementFloorplansBlock data={element} key={`ElementFloorplansBlock${element.ID}`}/>
            break
        case 'ElementFeaturedProducts':
            elementTemplate = <ElementFeaturedProducts data={element} key={`ElementFeaturedProducts${element.ID}`}/>
            break
        case 'ElementFeaturedProduct':
            elementTemplate = <ElementFeaturedProduct data={element} key={`ElementFeaturedProduct${element.ID}`}/>
            break
        case 'ElementRelatedBlogArticles':
            elementTemplate = <ElementRelatedBlogArticles data={element} key={`ElementRelatedBlogArticles${element.ID}`}/>
            break
        case 'ElementGallery':
            elementTemplate = <ElementGallery data={element} key={`ElementGallery${element.ID}`}/>
            break
        case 'ElementImageAndText':
            elementTemplate = <ElementImageAndText data={element} key={`ElementImageAndText${element.ID}`}/>
            break
        case 'ElementNewsletter':
            elementTemplate = <ElementNewsletter data={element} key={`ElementNewsletter${element.ID}`}/>
            break
        case 'ElementLocationMap':
            elementTemplate = <ElementLocationMap data={element} key={`ElementLocationMap${element.ID}`}/>
            break
        case 'ElementRecentViewed':
            elementTemplate = <ElementRecentViewed data={element} key={`ElementRecentViewed${element.ID}`}/>
            break
        case 'DNADesign\\ElementalUserForms\\Model\\ElementForm':
            elementTemplate = <ElementUserForm data={element} key={`ElementUserForm${element.ID}`}/>
            break
        default: break
    }
    return elementTemplate
}
export default Elements