import React from "react"
import { Parallax } from 'react-parallax'

const ElementFullWidthImage = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  
  var layoutClass = 'container-fluid px-0'
  if(element.DisplayOption == 'Container'){
      layoutClass = 'container'
  }else if(element.DisplayOption == 'Narrow'){
      layoutClass = 'container narrow'
  }
  var useParallax = element.UseParallax == 1
  return (
    <div id={`content-${element.ID}`} className={`element elementfullwidthimage`}>
      <section className={`section`} style={inlineStyle}>
        <div className={`${layoutClass}`}>
          {element.ShowTitle == 1 && (
            <h2 className="title title-long title-long-primary text-center">
              {element.Title}
            </h2>
          )}
          {element.Image !== "" && (
            <>
            {useParallax ? (
                <>
                {element.Link !== '' ? (
                <a href={element.Link}>
                    <Parallax className="d-none d-md-block" bgImage={element.Image} strength={300}>                                       
                        <img className="w-100 invisible" src={element.Image} alt=""/>
                    </Parallax>
                </a>
                ):(
                    <Parallax className="d-none d-md-block" bgImage={element.Image} strength={300}>                                       
                        <img className="w-100 invisible" src={element.Image} alt=""/>
                    </Parallax>
                )}
                </>
            ):(
                <>
                {element.Link !== '' ? (
                    <a href={element.Link}>
                        <img className="d-none d-md-block w-100 mx-auto" src={element.Image} alt=""/>
                    </a>
                ):(
                    <img className="d-none d-md-block w-100 mx-auto" src={element.Image} alt=""/>
                )}
                </>
            )}
            </>
          )}
        <>
            {useParallax ? (
                <>
                {element.TurnOffParallaxOnMobile == 1 ? (
                    <img className="d-sm-block d-md-none d-lg-none w-100 mx-auto" src={element.MobileImage ? element.MobileImage : element.Image} alt=""/>
                ):(
                    <Parallax className="d-block d-md-none" bgImage={element.MobileImage ? element.MobileImage : element.Image} strength={200}>
                        <img className="w-100 invisible" src={element.MobileImage ? element.MobileImage : element.Image} alt=""/>
                    </Parallax>
                )}
                </>
            ):(
                <img className="d-sm-block d-md-none d-lg-none w-100 mx-auto" src={element.MobileImage ? element.MobileImage : element.Image} alt=""/>
            )}
        </>
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementFullWidthImage
