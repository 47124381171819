import React, { useCallback, useState } from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { compose, withProps, withStateHandlers } from 'recompose'
import config from "../../config/config"

const GoogleMapWithMarker = compose(
  withStateHandlers(() => ({
    infoIndex: -1,
  }), {
    updateInfoIndex: () => infoIndex => ({
      infoIndex
    })
  }),
  withScriptjs,
  withGoogleMap
)(({ data, infoIndex, updateInfoIndex }) => {
  const element = JSON.parse(data)
  const marker_items = JSON.parse(element.jsonMap)
  
  var MapColour = element.MapColour
  var mapStyles = []
  if(MapColour !== ''){
        if(typeof MapColour == 'object'){
            mapStyles = MapColour
        }else{
            mapStyles = JSON.parse(MapColour)
        }
  }
  var init_latlng = { lat: -34.4183695090691, lng: 150.8939155495682 }
  var init_marker_img = "/img/marker-map-pink.svg"
  if(marker_items.length){
      var first_marker = marker_items[0]
      init_latlng = { lat: parseFloat(first_marker.lat), lng: parseFloat(first_marker.lng) }
  }
  const markers = marker_items.length ? marker_items.map((marker, idx) => {
    let lat = Number(marker.lat)
    let lng = Number(marker.lng)
    let marker_img = marker.markerImg !== null ? marker.markerImg : init_marker_img
    let iconMarker
    if (typeof window !== 'undefined') {
        iconMarker = new window.google.maps.MarkerImage(
          marker_img,
          null,null,null,new window.google.maps.Size(45, 45)
        )
    }
    return (
      <Marker icon={iconMarker} key={idx} position={{ lat: lat, lng: lng }} onClick={() => updateInfoIndex(idx)}>
        {(infoIndex == idx) && (
          <InfoWindow onCloseClick={() => updateInfoIndex(-1)}>
            <span dangerouslySetInnerHTML={{ __html: marker.content }}/>
          </InfoWindow>
        )}
      </Marker>
    )
  }) : []
  return <GoogleMap
    defaultZoom={12}
    defaultCenter={init_latlng}
    defaultOptions={{ styles: mapStyles }}>
    {markers}
  </GoogleMap>
})

const ElementLocationMap = ({ data }) => {
  const element = JSON.parse(data.Data)
  const default_zoom = 10
  const google_map_api_key = config.google_map_api_key
  const zoomLevel = element.Zoom !== 0 ? element.Zoom : default_zoom
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + 'rem'
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + 'rem'
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }

  return (
    <div id={`content-${element.ID}`} className="element elementlocationmap">
      <section className={`section`} style={inlineStyle}>
        <div className="container">
          {element.ShowTitle == 1 && (
            <h3 className="text-center mb-5"><span className="underline-7px">{element.Title}</span></h3>
          )}
          <div className="map-border mb-5">
            <GoogleMapWithMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${google_map_api_key}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className="map-inner" />}
              mapElement={<div style={{ height: `100%` }} />}
              zoom={zoomLevel}
              data={data.Data}
            />
          </div>
          <div
            className="font-recoleta fz-24 text-center"
            dangerouslySetInnerHTML={{ __html: element.Content }}
          />
        </div>
      </section>
      {element.LineDivider == 1 && (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      )}
    </div>
  )
}

export default ElementLocationMap